import React, {useState} from "react";
import PropTypes from "prop-types";

import Header from "../components/molecules/Header";

import GlobalStyles from "../components/atoms/GlobalStyles";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";

const Credit = styled.ul`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: 8px;
`

// const schemes = [
//   {primary: 'rgb(255,0,0)', secondary: 'rgb(255,255,0)'},
//   {primary: 'rgb(255, 165, 0)', secondary: 'rgb(0, 128, 0)'},
//   {primary: 'rgb(0, 0, 255)', secondary: 'rgb(255, 182, 193)'},
//   {primary: 'rgb(175, 238, 238)', secondary: 'rgb(178, 34, 34)'},
// ];

function Index({children}) {

  return (
    <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Header />
          <main>{children}</main>
          <Credit>Site by <a target="_blank" rel="noopener noreferrer"
                             href="https://wills-websites.com">W.W.</a></Credit>
        </>
    </ThemeProvider>
  );

}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
