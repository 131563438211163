import {Link} from "gatsby";
import React, {Component} from "react";
import styled from "styled-components";
import LinkIcon from '../../assets/svg/link.inline.svg';
import MailIcon from '../../assets/svg/mail.inline.svg';
import Newspaper from '../../assets/svg/newspaper.inline.svg';

const Holder = styled.header`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px dotted;
  height: 3rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    padding: 1rem;
  }
  h1 {
    margin-right: 1rem;
    @media( ${props => props.theme.breakpoints.sm} ) {
      margin-right: 2rem;
    }
  }
  svg {
    height: 1.2rem;
    width: auto;
    padding: 0 0.5rem;
    path {
      stroke: ${(props) => props.theme.primary};
    }
  }
`;

class Header extends Component {
  render() {
    return (
      <Holder>
        <h1 className="p"><Link to="/">Max Berry, artist, b. 1987</Link></h1>
        <p>
          <a href="https://linktr.ee/maxberry" target="_blank" rel="noopener noreferrer" title="Links" aria-label="Links"><LinkIcon/></a>
          <a href="mailto:info@maxberry.com.au" target="_blank" rel="noopener noreferrer" title="Email" aria-label="Email"><MailIcon/></a>
          <a href="https://maxberry.us8.list-manage.com/subscribe?u=a023a3eb34c1b855ed6913d3d&id=2ea391a0f5" target="_blank" rel="noopener noreferrer" title="Email sub" aria-label="Email sub"><Newspaper/></a>
        </p>
      </Holder>
    );
  }
}

export default Header;
